import {Button, Form, Segment} from "semantic-ui-react";
import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";
import AuthService from "../../services/AuthService";
import moment from "moment";

const MultiFactorAuthentication = ({t}) => {

    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        if (!code || code.trim() === "") {
            setError(t("MULTI_FACTOR_AUTHENTICATION_ERROR", "Please supply a valid code"));
            return;
        }
        setError('');

        try {
            const response = await StaffService.checkMultiFactorAuthenticationCode(code);
            const expiryMoment = moment().add(response.expires_in, "seconds");
            AuthService.AUTH_TOKEN = response.access_token;
            AuthService.TOKEN_EXPIRY = expiryMoment;
            localStorage.setItem("AUTH_TOKEN", AuthService.AUTH_TOKEN);
            localStorage.setItem("TOKEN_EXPIRY", AuthService.TOKEN_EXPIRY.toString());

            AuthService.redirectAfterLogin();
        } catch (e) {
            setError(t("MULTI_FACTOR_AUTHENTICATION_INCORRECT_CODE", "Invalid code"));
        }
    };

    return (
        <>
            <Page
                name="MULTI_FACTOR_AUTHENTICATION"
                header={t("MULTI_FACTOR_AUTHENTICATION_HEADER", "Multi Factor Authentication")}
                subheader={t("MULTI_FACTOR_AUTHENTICATION_SUBHEADER", "Please enter the code that has been emailed to you!")}>

                <Form size="large">
                    <Segment>
                        <Form.Input
                            fluid
                            icon={"lock"}
                            iconPosition="left"
                            onChange={(e) => setCode(e.target.value)}
                            placeholder={t("MULTI_FACTOR_AUTHENTICATION_CODE", "Multi Factor Authentication code!")}
                        />
                        {error && <div style={{color:'red', padding:10}}>{error}</div>}
                        <Button
                            type="submit"
                            primary
                            fluid
                            size="large"
                            onClick={handleSubmit}
                        >
                            {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                        </Button>
                    </Segment>
                </Form>

            </Page>
        </>
    );

}

export default withTranslation()(MultiFactorAuthentication);
